import Sortable from 'sortablejs';

let Flickity = require('flickity'),
    csrf = $('meta[name="csrf-token"]').attr('content');

$(function () {
    $('.lfm').filemanager('image');
    $('.lfm-multiple-image').each(function () {
        $(this).filemanager('image', {callback: $(this).data('callback')});
    });

    $('.lfm-file').filemanager('file');
});

$(function () {
    var fs = '#gallery .gallery-carousel',
        $m = $('#modal-add-gallery-image'),
        $c,
        staticClick = function (event, pointer, cellElement, cellIndex) {
            if (event.target.classList.contains('order')) {
                orderGallery($(cellElement), cellIndex, event.target.getAttribute('data-order'), $c);
                return;
            }
            $m.find('.multiple-item').remove();
            $m.find('input').val('');
            $m.find('img').attr('src', '');
            var id = $(cellElement).data('index') !== undefined ? $(cellElement).data('index') : 'new';
            if ($(cellElement).hasClass('add-new')) {
                $m.modal('show').attr('data-index', 'new').find('.btn-danger').hide()
            } else {
                var el = $(cellElement),
                    image = el.attr('data-image'),
                    title = el.find('input[data-type="title"]').val();

                $m.find('input[data-type="image"]').val(image);
                $m.find('img').attr('src', image);
                $m.find('input[data-type="title"]').val(title);
                $m.modal('show').attr('data-index', id).find('.btn-danger').show()
            }
        };

    $c = createSlider(fs, $m, staticClick);

    $m.on('click', '.save', function () {
        var images = [];
        var titles = [];
        $m.find('input[data-type="image"]').each(function () {
            images.push($(this).val())
        });
        $m.find('input[data-type="title"]').each(function () {
            titles.push($(this).val());
        });

        var index = $m.attr('data-index');

        if (index !== 'new') {
            var carItem = $(fs + ' .carousel-cell[data-index="' + index + '"]');
            carItem.find('input[data-type="image"]').val(images[0]);
            carItem.find('input[data-type="title"]').val(titles[0]);
            carItem.find('img').attr('src', images[0]);
            carItem.attr('data-image', images[0]);
        } else {
            for (var i = 0; images.length > i; i++) {
                addToGallery(images[i], titles[i]);
            }
        }

        $c.resize();
    }).on('click', '.remove', function () {
        var index = $m.attr('data-index');
        $c.remove($(fs).find('.carousel-cell[data-index=' + index + ']'));
    });

    function addToGallery(image, title) {
        var cells = $c.cells.length,
            order = cells + 1;
        cells = cells - 1;
        var html = $('<div class="carousel-cell" data-index="' + cells + '" data-image="' + image + '"><span class="fal fa-arrow-left order" data-order="prev" aria-hidden="true"></span><span class="fal fa-arrow-right order" data-order="next" aria-hidden="true"></span><img src="' + image + '" alt="" class="img-fluid"><input type="hidden" name="gallery[' + cells + '][image]" value="' + image + '"><input type="hidden" class="order" name="gallery[' + cells + '][order]" value="' + order + '"><input type="hidden" name="gallery[' + cells + '][title]" value="' + title + '"></div>');
        $c.append(html);
    }
});

function orderGallery(element, currentIndex, order, carousel) {
    var nextIndex;
    currentIndex--;
    if (order === 'prev') {
        if (currentIndex >= 0) {
            nextIndex = currentIndex - 1;
        }
    } else {
        nextIndex = currentIndex + 1;
    }

    if (nextIndex !== undefined) {
        var parent = element.parents('.gallery-carousel'),
            nextItem = parent.find('[data-index="' + nextIndex + '"]');
        if (nextItem.length) {
            if (order === 'prev') {
                nextItem.before(element);
            } else {
                nextItem.after(element);
            }

            parent.find('[data-index]').each(function (index) {
                $(this).attr('data-index', index);
                $(this).find('input.order').val(index + 1);
            });

            carousel.reloadCells();
        }
    }
}

$(function () {
    var fs = '#infrastructure .infrastructure-carousel',
        $m = $('#modal-add-infrastructure-item'),
        colorInput = '#infrastructureColor',
        letterInput = '#infrastructureLetter',
        titleInput = '#infrastructureTitle',
        cxInput = '#infrastructureCX',
        cyInput = '#infrastructureCY',
        //Genplan SVG
        frontGenPlanWidth = 1045,
        frontGenPlanHeight = 750,
        genPlanWidth = $m.find('svg').width(),
        genPlanHeight,
        extraCircle = '.extra-circles',
    staticClick = function (event, pointer, cellElement, cellIndex) {
        $(extraCircle).remove();
        $m.find('input').val('');
        var id = $(cellElement).data('index') !== undefined ? $(cellElement).data('index') : 'new';
        if ($(cellElement).hasClass('add-new')) {
            $m.modal('show').attr('data-index', 'new').find('.btn-danger').hide()
        } else {
            var el = $(cellElement),
                color = el.attr('data-color'),
                title = el.attr('data-title'),
                letter = el.attr('data-letter'),
                rounds = getExtraCircle(el);

            $m.find(colorInput).val(color);
            $m.find(letterInput).val(letter);
            $m.find(titleInput).val(title);
            setTimeout(function () {
                for (var i = 0; rounds.length > i; i++) {
                    var cx = rounds[i].cx,
                        cy = rounds[i].cy;
                    if (i) {
                        addExtraCircleHTML(i, cx, cy, color)
                    } else {
                        $m.find(cxInput).val(cx);
                        $m.find(cyInput).val(cy);
                        $m.find('svg circle.first').attr('cy', getInfrastructureCoord(cy));
                        $m.find('svg circle.first').attr('cx', getInfrastructureCoord(cx));
                        $m.find('svg circle.first:last-child').attr('fill', color);
                    }
                }
            }, 600);


            $m.modal('show').attr('data-index', id).find('.btn-danger').show()
        }

        $(colorInput).ColorPicker({
            onSubmit: function (hsb, hex, rgb, el) {
                $(el).val(hex);
                $(el).ColorPickerHide();
            },
            onBeforeShow: function () {
                $(this).ColorPickerSetColor(this.value);
            }
        }).bind('keyup', function () {
            $(this).ColorPickerSetColor(this.value);
        });
    };

    function getExtraCircle(el) {
        var rounds = [];
        el.find('[data-cx]').each(function () {
            var index = $(this).attr('data-round');
            rounds.push({
                cx: $(this).val(),
                cy: $(this).siblings('[data-round="' + index + '"]').val()
            });
        });

        return rounds;
    }

    $('.add-infrastructure-circle').click(function () {
        var color = $m.find(colorInput).val();
        addExtraCircleHTML($(extraCircle).length + 1, 300, 150, color);
    });

    function addExtraCircleHTML(i, cx, cy, color) {
        var circleHtml = '';
        var html = '<div class="' + extraCircle.replace('.', ' ') + '">';
        var allHtml = getExtraCircleHTML(i, cx, cy, color);
        html += allHtml.input;
        html += '</div>';
        circleHtml += allHtml.circle;

        $m.find(cyInput).after(html);
        $m.find('svg .is_selected').removeClass('is_selected');
        $m.find('svg image').after(circleHtml);
        var svg = $m.find('svg').parent().html();
        $('svg').parent().html(svg);
    }

    function getExtraCircleHTML(i, cx, cy, color) {
        var html = '<input type="hidden" data-cx data-round="'+i+'" id="infrastructureCX' + i + '" value="' + cx + '">' +
            '<input type="hidden" data-cy data-round="'+i+'" id="infrastructureCY' + i + '" value="' + cy + '">';
        var circleHtml = '<g>\n' +
            '<circle cy="' + getInfrastructureCoord(cy) + '" data-input-id="' + i + '" ' +
            'cx="' + getInfrastructureCoord(cx) + '" onclick="selectCircle(this)" class="is_selected ' + extraCircle.replace('.', ' ') + '" fill="#fff" r="20" opacity=".8"></circle>\n' +
            '<circle cy="' + getInfrastructureCoord(cy) + '" data-input-id="' + i + '" ' +
            'cx="' + getInfrastructureCoord(cx) + '" onclick="selectCircle(this)" class="' + extraCircle.replace('.', ' ') + '" fill="' + color + '" r="16"></circle>\n' +
            '</g>';

        return {
            input: html,
            circle: circleHtml
        }
    }

    function getInfrastructureCoord(coord) {
        return genPlanHeight / frontGenPlanHeight * coord
    }

    var $c = createSlider(fs, $m, staticClick);

    $m.on('click', '.save', function () {
        var index = $m.attr('data-index');
        $m.find('.error.title').remove();
        $m.find(titleInput).parents('.form-group').removeClass('has-error');

        var color = $m.find(colorInput).val(),
            letter = $m.find(letterInput).val(),
            title = $m.find(titleInput).val(),
            cx = $m.find(cxInput).val(),
            cy = $m.find(cyInput).val(),
            titles = [],
            html = '';

        $('.infrastructure-title').each(function () {
            titles.push($(this).val().toLowerCase());
        });

        if(index === 'new') {
            if (titles.indexOf(title.toLowerCase()) !== -1) {
                $m.find(titleInput).parents('.form-group').addClass('has-error');
                $m.find(titleInput).after('<div class="error title">Такое название уже добавлено</div>')
                return;
            }
        }

        if (color.indexOf('#') === -1) {
            color = '#' + color;
        }

        var circles = getExtraCircle($m);
        if (index !== 'new') {
            var carItem = $(fs + ' .carousel-cell[data-index="' + index + '"]');
            carItem.find('input[data-color]').val(color);
            carItem.find('input[data-letter]').val(letter);
            carItem.find('input[data-title]').val(title);
            carItem.find('input[data-cx]').val(cx);
            carItem.find('input[data-cy]').val(cy);
            carItem.attr('data-color', color);
            carItem.attr('data-letter', letter);
            carItem.attr('data-title', title);
            carItem.children().children(':first-child').html(title);
            carItem.children().children(':last-child').html(letter);
            carItem.children().css({
                backgroundColor: color
            });
        } else {
            index = $c.cells.length;

            html = '<div class="carousel-cell" data-index="' + index + '" data-color="' + color + '" ' +
                'data-letter="' + letter + '" data-cx="' + cx + '" data-cy="' + cy + '" data-title="' + title + '"> ' +
                '<div style="background-color: ' + color + '">' +
                '<div class="text-center">' + title + '</div><div class="text-center">' + letter + '</div>' +
                '</div>' +
                '<input type="hidden" name="infrastructure[' + index + '][color]" data-color value="' + color + '">' +
                '<input type="hidden" class="infrastructure-title" name="infrastructure[' + index + '][title]" ' +
                'data-title value="' + title + '">' +
                '<input type="hidden" name="infrastructure[' + index + '][letter]" data-letter value="' + letter + '"></div>';
            $c.insert($(html), 1);
        }

        html ='';
        for (var i = 0; circles.length > i; i++) {
            html += '<input type="hidden" data-round="'+i+'" name="infrastructure[' + index + '][rounds][' + i + '][cx]" data-cx value="' + circles[i].cx + '">' +
                '<input type="hidden" data-round="'+i+'" name="infrastructure[' + index + '][rounds][' + i + '][cy]" data-cy value="' + circles[i].cy + '">';
        }

        $(fs).find('.carousel-cell[data-index="'+index+'"] input[data-cx], .carousel-cell[data-index="'+index+'"] input[data-cy]').remove();
        $(fs).find('.carousel-cell[data-index="'+index+'"]').append(html);
        $c.resize();
        $m.modal('hide');
    }).on('click', '.remove', function () {
        var index = $m.attr('data-index');
        $c.remove($(fs).find('.carousel-cell[data-index=' + index + ']'));
    }).on('click', 'svg', function (evt) {
        var e = evt.target,
            dim = e.getBoundingClientRect(),
            x = evt.clientX - dim.left,
            y = evt.clientY - dim.top,
            circle = $(this).find('circle.is_selected').parent().find('circle');

        circle.attr('cy', y);
        circle.attr('cx', x);
        var inputId = '';
        if (circle.attr('data-input-id') !== undefined) {
            inputId = circle.attr('data-input-id');
        }

        $(cxInput + inputId).val(frontGenPlanWidth / genPlanWidth * x);
        $(cyInput + inputId).val(frontGenPlanHeight / genPlanHeight * y);
    }).on('click', 'svg circle', function (e) {
        e.stopPropagation();
    }).on('dblclick', 'svg circle', function (e) {
        var inputId = $(this).attr('data-input-id');
        $m.find(cyInput+inputId).remove();
        $m.find(cxInput+inputId).remove();
        $(this).parent().remove();
        var svg = $m.find('svg').parent().html();
        $('svg').parent().html(svg);
        e.stopPropagation();
    }).on('shown.bs.modal', function () {
        genPlanHeight = $(this).find('svg').height();
    });
});

window.selectCircle = function (ithis) {
    $('.is_selected').removeClass('is_selected');
    var el = $(ithis);
    if ($(ithis).attr('r') < 18) {
        el = el.siblings('circle');
    }

    el.addClass('is_selected');
};

$(function () {
    var fs = '#experts .experts-carousel',
        $m = $('#modal-add-expert-item'),
        nameInput = '#expertName',
        positionInput = '#expertPosition',
        imageInput = '#expertImage',
        descriptionInput = '#expertDescription',
        staticClick = function (event, pointer, cellElement, cellIndex) {
            $m.find('input').val('');
            $m.find('img').attr('src', '');
            var id = $(cellElement).data('index') !== undefined ? $(cellElement).data('index') : 'new';
            if ($(cellElement).hasClass('add-new')) {
                $(positionInput).val('Эксперт проекта');
                $m.modal('show').attr('data-index', 'new').find('.btn-danger').hide()
            } else {
                var el = $(cellElement),
                    name = el.attr('data-name'),
                    image = el.attr('data-image'),
                    position = el.attr('data-position'),
                    description = el.attr('data-description');

                $m.find(nameInput).val(name);
                $m.find(positionInput).val(position);
                $m.find(imageInput).val(image);
                $m.find(descriptionInput).val(description);
                $m.find('img').attr('src', image);
                $m.modal('show').attr('data-index', id).find('.btn-danger').show()
            }
        };

    var $c = createSlider(fs, $m, staticClick);

    $m.on('click', '.save', function () {
        var name = $m.find(nameInput).val(),
            position = $m.find(positionInput).val(),
            image = $m.find(imageInput).val(),
            description = $m.find(descriptionInput).val(),
            index = $m.attr('data-index');

        if (index !== 'new') {
            var carItem = $(fs + ' .carousel-cell[data-index="' + index + '"]');
            carItem.find('input[data-name]').val(name);
            carItem.find('input[data-letter]').val(position);
            carItem.find('input[data-title]').val(image);
            carItem.find('input[data-description]').val(description);
            carItem.attr('data-name', name);
            carItem.attr('data-position', position);
            carItem.attr('data-image', image);
            carItem.attr('data-description', description);
            carItem.children().children(':first-child').html(name);
            carItem.children().children(':last-child').html(position);
        } else {
            var cells = $c.cells.length;
            var html = $('<div class="carousel-cell" data-index="' + cells + '"\n' +
                '                 data-image="' + image + '"\n' +
                '                 data-name="' + name + '"\n' +
                '                 data-position="' + position + '"\n' +
                '                 data-description="' + description + '">\n' +
                '                <div style="background-image: url(' + image + ')">\n' +
                '                    <div class="text-center">' + name + '</div>\n' +
                '                    <div class="text-center">' + position + '</div>\n' +
                '                </div>\n' +
                '                <input type="hidden" name="experts[' + cells + '][image]" data-image\n' +
                '                       value="' + image + '">\n' +
                '                <input type="hidden" name="experts[' + cells + '][description]" data-description\n' +
                '                       value="' + description + '">\n' +
                '                <input type="hidden" name="experts[' + cells + '][name]" data-name\n' +
                '                       value="' + name + '">\n' +
                '                <input type="hidden" name="experts[' + cells + '][position]" data-position\n' +
                '                       value="' + position + '">\n' +
                '            </div>');
            $c.insert(html, 1);
        }
        $c.resize();
    }).on('click', '.remove', function () {
        var index = $m.attr('data-index');
        $c.remove($(fs).find('.carousel-cell[data-index=' + index + ']'));
    });
});

function createSlider(fs, $m, staticClick) {
    if (!$(fs).length) {
        return;
    }
    var $c = new Flickity(fs, {
        contain: true,
        pageDots: false,
        cellAlign: 'left',
        prevNextButtons: false,
        on: {
            staticClick: staticClick
        }
    });

    setTimeout(function () {
        $c.resize();
    }, 20);

    return $c;
}

$(function () {
    var galleryRow = $('.gallery-item').length;
    $('.add-gallery-image').click(function () {
        var html = $('#template-gallery-item').html();
        html = replaceAll(html, 'previewIndex', galleryRow);
        $('#gallery').find('.add-gallery-image').parent().before(html);
        galleryRow++;
    });
});

$(function () {
    $(document).on('click', '.delete', function () {
        var ithis = $(this),
            buttonText = ithis.html(),
            url = ithis.data('url');

        $.ajax({
            url: url,
            method: 'DELETE',
            data: {
                _token: csrf
            },
            beforeSend: function () {
                ithis.html('<i class="fal fa-spinner fa-spin"></i>')
            },
            complete: function () {
                ithis.html(buttonText);
            },
            success: function (json) {
                ithis.parent().parent().remove()
            },
            error: function () {
                alert('Произошла ошибка при удалении')
            }
        })
    })
})

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

$(function () {
    $('.remove-image').click(function () {
        $(this).parents('.image-block').remove();
    });

    $('.images-sortable').each(function () {
        let _this = $(this);
        new Sortable(this, {
            onEnd: function () {
                _this.find('.sort-order').each(function (i) {
                    $(this).val(i)
                });
            }
        })
    });
})