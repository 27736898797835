/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./lfm');
require('./colorpicker');
require('./functions');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('project-label-selector', require('./components/ProjectLabelSelector.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

$(function () {
    $(document).on('change', '#map_block', function () {
        var matches = Array.from($('#map_block').val().matchAll(/src="(.*?)"/gmi), function (match) {
            return match[1];
        })

        switch (matches.length)
        {
            /* В случае если был предоставлен iframe возвращаем src */
            case 1:
                return $('#map_block').val(matches[0]);
                break;
        }
    })

    $(document).on('change', '#map_block', function() {
        $(document).trigger('ww.blocks_constructor.setPropSelectBtn', {disabled: false});
    })
})
